import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Button, Flex, HStack, Text, VStack } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { IconMail } from '@tabler/icons-react'
import { useForgottenPasswordResetMutation } from 'generated/generated-graphql'
import { InferType } from 'yup'
import * as yup from 'yup'
import { FormTextInput } from 'components/inputs'
import { SignInStep } from './signIn'

export enum FieldName {
  Email = 'email',
}

export const YupSignInValidationSchema = yup.object({
  [FieldName.Email]: yup
    .string()
    .email('E-mail musí byť platný')
    .required('* Povinné')
    .typeError('E-mail musí byť platný'),
})

export type FormValues = InferType<typeof YupSignInValidationSchema>

export const ForgottenPasswordForm = ({
  changeStep,
}: {
  changeStep: (value: SignInStep) => void
}) => {
  const [sendResetPasswordEmail, { loading: isLoading }] = useForgottenPasswordResetMutation()
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)
  const methods = useForm<FormValues>({
    defaultValues: {
      [FieldName.Email]: '',
    },
    resolver: yupResolver(YupSignInValidationSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  const {
    watch,
    reset,
    handleSubmit,
    formState: { errors: formErrors },
  } = methods
  const emailWatch = watch(FieldName.Email)

  const submit = async ({ email }: FormValues) => {
    await sendResetPasswordEmail({
      variables: { email },
      onCompleted: () => setShowSuccessMessage(true),
    })
  }

  return (
    <FormProvider {...methods}>
      {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
      <form style={{ width: '100%' }} onSubmit={handleSubmit(submit)}>
        {showSuccessMessage ? (
          <VStack alignItems="center" spacing={4} mx={6} my={8}>
            <IconMail size="88px" stroke={1.5} />
            <Text fontFamily="DM Sans Bold" fontSize="xl" color="brand.blue100">
              Skontrolujte Vašu emailovú schránku.
            </Text>
            <Text align="center">
              Skontrolujte Vašu e-mailovú schránku. Pokyny pre obnovenie hesla sme Vám poslali na
              <strong>{emailWatch}</strong>. Stále nevidíte od nás žiadny e-mail? Pre istotu
              skontrolujte aj svoju nevyžiadanú poštu!
            </Text>
            <Flex w="full" pt="24px">
              <Button
                variant="link"
                fontSize="16px"
                onClick={() => {
                  reset()
                  setShowSuccessMessage(false)
                }}
                textDecor="underline"
              >
                Skúste inú emailovú adresu
              </Button>
            </Flex>
          </VStack>
        ) : (
          <>
            <VStack spacing={0}>
              <Text align="start" fontSize="16px" mt="16px" pb="24px">
                Stačí, keď vyplníte svoj e-mail a obdržíte pokyny na jeho obnovenie.
              </Text>
              <FormTextInput
                id={FieldName.Email}
                label="Email"
                placeholder="Zadajte email"
                errorMessage={formErrors.email?.message}
              />
            </VStack>
            <HStack>
              <Button
                mt="32px"
                size="lg"
                w="full"
                variant="outline"
                isDisabled={isLoading}
                onClick={() => {
                  changeStep(SignInStep.SignIn)
                }}
              >
                Zrušiť
              </Button>
              <Button
                mt="32px"
                size="lg"
                w="full"
                isDisabled={isLoading}
                isLoading={isLoading}
                type="submit"
              >
                Potvrdiť
              </Button>
            </HStack>
          </>
        )}
      </form>
    </FormProvider>
  )
}
