import { FormProvider, useForm } from 'react-hook-form'
import { Button, VStack } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import Link from 'next/link'
import { InferType } from 'yup'
import * as yup from 'yup'
import { Route } from 'constants/common/routes'
import { FormPasswordInput, FormTextInput } from 'components/inputs'
import { useAuth } from 'providers/UserProvider'
import { SignInStep } from './signIn'

export enum FieldName {
  Email = 'email',
  Password = 'password',
}

export const YupSignInValidationSchema = yup.object({
  [FieldName.Email]: yup
    .string()
    .email('E-mail musí byť platný')
    .required('* Povinné')
    .typeError('E-mail musí byť platný'),
  [FieldName.Password]: yup.string().required('*Povinné'),
})

export type YupSignInFormValues = InferType<typeof YupSignInValidationSchema>

export const SignInForm = ({ changeStep }: { changeStep?: (value: SignInStep) => void }) => {
  const { signInWithEmailAndPassword, isLoading } = useAuth()
  const methods = useForm<YupSignInFormValues>({
    defaultValues: {
      [FieldName.Email]: '',
      [FieldName.Password]: '',
    },
    resolver: yupResolver(YupSignInValidationSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  })

  const submit = async ({ email, password }: YupSignInFormValues) => {
    await signInWithEmailAndPassword({
      email,
      password,
    })
  }
  const {
    formState: { errors: formErrors },
  } = methods

  return (
    <FormProvider {...methods}>
      {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
      <form style={{ width: '100%' }} onSubmit={methods.handleSubmit(submit)}>
        <VStack spacing="16px" align="start">
          <FormTextInput
            id={FieldName.Email}
            label="Email"
            placeholder="Zadajte email"
            errorMessage={formErrors.email?.message}
          />
          <FormPasswordInput
            id={FieldName.Password}
            label="Heslo"
            placeholder="Zadajte heslo"
            errorMessage={formErrors.password?.message}
          />
          <Button
            variant="link"
            fontSize="14px"
            onClick={() => {
              changeStep?.(SignInStep.ForgottenPassword)
            }}
          >
            Zabudli ste heslo?
          </Button>
        </VStack>
        <VStack mt="32px" w="full" spacing="16px">
          <Button size="lg" w="full" type="submit" isLoading={isLoading} isDisabled={isLoading}>
            Prihlásiť sa
          </Button>
          <Link href={Route.SignUp()} style={{ width: '100%' }}>
            <Button w="full" variant="outline" size="lg">
              Nemáte účet?
            </Button>
          </Link>
        </VStack>
      </form>
    </FormProvider>
  )
}
