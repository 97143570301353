import { useEffect } from 'react'
import { Button, Container, Divider, Flex, HStack, useDisclosure, VStack } from '@chakra-ui/react'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { Route } from 'constants/common/routes'
import { SignInModal } from 'components/SignIn/SignInModal'
import { useAuth } from 'providers/UserProvider'
import { NavbarButton } from './NavbarButton'

export const LandingPageNavbar = () => {
  const { userData } = useAuth()
  const { query } = useRouter()

  const {
    isOpen: isSignInModalOpen,
    onOpen: onSignInModalOpen,
    onClose: onSignInModalClose,
  } = useDisclosure({ defaultIsOpen: Boolean(query.openSignInModal) })

  useEffect(() => {
    if (query.openSignInModal) {
      onSignInModalOpen()
    }
  }, [onSignInModalOpen, query.openSignInModal])

  return (
    <>
      <Flex
        w="full"
        bg="white"
        borderBottomColor="brand.blue20"
        borderBottomWidth="1px"
        boxShadow="sm"
      >
        <Container maxW="container.xl" px="32px" py="10px" centerContent>
          <Flex w="full" h="auto" justify="space-between" align="center">
            <Link href={Route.FrontPage()}>
              <Image src="/icons/zadaj-logo.png" width={120} height={62} alt="logo" />
            </Link>
            <HStack
              spacing={{ base: '16px', lg: '32px' }}
              divider={<Divider borderColor="#CCCCCC" orientation="vertical" h="78px" />}
            >
              <HStack spacing={{ base: '16px', lg: '32px' }}>
                <NavbarButton label="Dopyty" href={Route.Public.DemandList()} />
                <NavbarButton label="Priame ponuky" href={Route.Public.DirectOffers()} />
                <NavbarButton label="Dodávatelia" href={Route.Public.ContractorLanding()} />
                <NavbarButton label="Rady & Tipy" href={Route.Blog()} />
              </HStack>
              {userData ? (
                <Link href={Route.App.DemandList()}>
                  <Button>Vstupit do portalu</Button>
                </Link>
              ) : (
                <VStack spacing="8px">
                  <Link href={Route.Public.CreateDemand()}>
                    <Button w={{ base: '160px', lg: '185px' }} h="53px">
                      Vytvoriť dopyt
                    </Button>
                  </Link>
                  <Flex w="full" justify="space-between">
                    <Button
                      variant="tertiary"
                      fontFamily="Montserrat"
                      fontSize="12px"
                      lineHeight="20px"
                      p={0}
                      onClick={() => onSignInModalOpen()}
                    >
                      Prihlásenie
                    </Button>
                    <Divider borderColor="#CCCCCC" orientation="vertical" h="20px" />
                    <Link href={Route.SignUp()}>
                      <Button
                        variant="tertiary"
                        fontFamily="Montserrat"
                        fontSize="12px"
                        lineHeight="20px"
                        p={0}
                      >
                        Registrácia
                      </Button>
                    </Link>
                  </Flex>
                </VStack>
              )}
            </HStack>
          </Flex>
        </Container>
      </Flex>
      <SignInModal isOpen={isSignInModalOpen} onClose={onSignInModalClose} />
    </>
  )
}
