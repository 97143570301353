import React, { useState } from 'react'
import { Button, Divider, Flex, ModalProps, Text, VStack } from '@chakra-ui/react'
import { IconBrandGoogle } from '@tabler/icons-react'
import { AuthProvider, UserType } from 'generated/generated-graphql'
import { Modal } from 'components/ui/Modal'
import { useAuth } from 'providers/UserProvider'
import { ForgottenPasswordForm } from './ForgottenPasswordForm'
import { SignInStep } from './signIn'
import { SignInForm } from './SignInForm'

interface SignInModalProps extends Omit<ModalProps, 'children'> {
  onSuccess?: () => void
  isOpen: boolean
  onClose: () => void
}

export const SignInModal = ({ onSuccess, isOpen, onClose, ...rest }: SignInModalProps) => {
  const { createUserWithGoogleOAuth } = useAuth()
  const [activeStep, setActiveStep] = useState(SignInStep.SignIn)

  const titleMapper: Record<SignInStep, string> = {
    [SignInStep.SignIn]: 'Prihlásenie',
    [SignInStep.SignUp]: 'Registracia',
    [SignInStep.ForgottenPassword]: 'Zabudli ste heslo? ',
    [SignInStep.CreateNewPassword]: 'Zadajte nové heslo',
  }

  return (
    <Modal
      title={titleMapper[activeStep]}
      isOpen={isOpen}
      onClose={() => {
        setActiveStep(SignInStep.SignIn)
        onClose()
      }}
      hasCloseIcon
      {...rest}
    >
      {activeStep === SignInStep.SignIn && (
        <VStack spacing="16px">
          <Button
            w="full"
            colorScheme="google"
            bg="crimson"
            leftIcon={<IconBrandGoogle stroke={1.5} />}
            iconSpacing="16px"
            border="none"
            onClick={() => {
              void createUserWithGoogleOAuth({
                authProvider: AuthProvider.Google,
                userType: UserType.Customer,
                // TODO this is not needed remove later
                email: 'test',
                password: 'test',
                shouldRedirect: true,
              })
            }}
          >
            Prihlásiť sa cez Google
          </Button>
          <Flex w="full" align="center" gap="16px">
            <Divider w="full" />
            <Text fontFamily="DM Sans Medium" whiteSpace="nowrap">
              alebo cez
            </Text>
            <Divider w="full" />
          </Flex>
          <SignInForm
            changeStep={(value) => {
              setActiveStep(value)
            }}
          />
        </VStack>
      )}

      {activeStep === SignInStep.ForgottenPassword && (
        <ForgottenPasswordForm
          changeStep={(value) => {
            setActiveStep(value)
          }}
        />
      )}
    </Modal>
  )
}
