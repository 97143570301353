import {
  Button,
  Container,
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Spacer,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import { IconMenu2, IconX } from '@tabler/icons-react'
import Image from 'next/image'
import Link from 'next/link'
import { Route } from 'constants/common/routes'
import { SignInModal } from 'components/SignIn/SignInModal'
import { useAuth } from 'providers/UserProvider'
import { NavbarButton } from './NavbarButton'

export const LandingPageNavbarMobile = () => {
  const { userData } = useAuth()

  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    isOpen: isSignInModalOpen,
    onOpen: onSignInModalOpen,
    onClose: onSignInModalClose,
  } = useDisclosure()

  return (
    <>
      <Flex
        w="full"
        bg="white"
        borderBottomColor="brand.blue20"
        borderBottomWidth="1px"
        boxShadow="sm"
      >
        <Container maxW="container.xl" px="16px" py="10px" centerContent>
          <Flex w="full" h="auto" justify="space-between" align="center">
            <Link href={Route.FrontPage()}>
              <Image src="/icons/zadaj-logo.png" width={120} height={62} alt="logo" />
            </Link>
            <Button variant="ghost" onClick={onOpen}>
              <IconMenu2 size="36px" />
            </Button>

            <Drawer onClose={onClose} isOpen={isOpen} size="full">
              <DrawerOverlay />

              <DrawerContent>
                <DrawerHeader borderBottom="1px" borderColor="gray.200">
                  <Flex justify="space-between" align="start">
                    <Link
                      href={Route.FrontPage()}
                      onClick={() => {
                        onClose()
                      }}
                    >
                      <Image src="/icons/zadaj-logo.png" width={120} height={62} alt="logo" />
                    </Link>
                    <IconX size="36px" color="black" onClick={onClose} />
                  </Flex>
                </DrawerHeader>

                <DrawerBody px="24px">
                  <VStack
                    minH="full"
                    pb="24px"
                    align="start"
                    spacing="16px"
                    // divider={<Divider borderColor="#CCCCCC" />}
                  >
                    <VStack align="start" spacing="16px">
                      <NavbarButton
                        label="Dopyty"
                        href={Route.Public.DemandList()}
                        onClick={() => {
                          onClose()
                        }}
                      />
                      <VStack align="start" px="16px" spacing={0} color="brand.darkBlue80">
                        <Link
                          href={
                            userData
                              ? Route.App.DemandList({ listingTypeId: 1 })
                              : Route.Public.DemandList({ listingTypeId: 1 })
                          }
                          onClick={() => {
                            onClose()
                          }}
                          prefetch={false}
                          style={{
                            fontSize: '16px',
                            lineHeight: '150%',
                            color: 'inherit',
                          }}
                        >
                          Súkromné
                        </Link>
                        <Link
                          href={
                            userData
                              ? Route.App.DemandList({ listingTypeId: 2 })
                              : Route.Public.DemandList({ listingTypeId: 2 })
                          }
                          onClick={() => {
                            onClose()
                          }}
                          prefetch={false}
                          style={{
                            fontSize: '16px',
                            lineHeight: '150%',
                            color: 'inherit',
                          }}
                        >
                          Firemné
                        </Link>
                        <Link
                          href={
                            userData
                              ? Route.App.DemandList({ listingTypeId: 3 })
                              : Route.Public.DemandList({ listingTypeId: 3 })
                          }
                          onClick={() => {
                            onClose()
                          }}
                          prefetch={false}
                          style={{
                            fontSize: '16px',
                            lineHeight: '150%',
                            color: 'inherit',
                          }}
                        >
                          Pre bytové domy
                        </Link>
                        <Link
                          href={
                            userData
                              ? Route.App.DemandList({ listingTypeId: 4 })
                              : Route.Public.DemandList({ listingTypeId: 4 })
                          }
                          onClick={() => {
                            onClose()
                          }}
                          prefetch={false}
                          style={{
                            fontSize: '16px',
                            lineHeight: '150%',
                            color: 'inherit',
                          }}
                        >
                          Havarijné službyy
                        </Link>
                      </VStack>
                      <NavbarButton
                        label="Priame ponuky"
                        href={Route.Public.DirectOffers()}
                        onClick={() => {
                          onClose()
                        }}
                      />
                      <NavbarButton
                        label="Dodávatelia"
                        href={Route.Public.ContractorLanding()}
                        onClick={() => {
                          onClose()
                        }}
                      />
                      <NavbarButton
                        label="Rady & Tipy"
                        href={Route.Blog()}
                        onClick={() => {
                          onClose()
                        }}
                      />
                    </VStack>
                    <Spacer />
                    <Divider borderColor="#CCCCCC" />
                    <Flex w="full" justify="center" gap="24px">
                      <Button
                        variant="tertiary"
                        fontFamily="Montserrat"
                        fontSize="20px"
                        lineHeight="20px"
                        p={0}
                        onClick={() => {
                          onSignInModalOpen()
                          onClose()
                        }}
                      >
                        Prihlásenie
                      </Button>
                      <Divider borderColor="#CCCCCC" orientation="vertical" h="20px" />
                      <Link href={Route.SignUp()}>
                        <Button
                          variant="tertiary"
                          fontFamily="Montserrat"
                          fontSize="20px"
                          lineHeight="20px"
                          p={0}
                          onClick={() => {
                            onClose()
                          }}
                        >
                          Registrácia
                        </Button>
                      </Link>
                    </Flex>
                    <Divider borderColor="#CCCCCC" />
                    {userData ? (
                      <Link href={Route.App.DemandList()} style={{ width: '100%' }}>
                        <Button
                          w="full"
                          onClick={() => {
                            onClose()
                          }}
                        >
                          Vstúpiť do portálu
                        </Button>
                      </Link>
                    ) : (
                      <Link href={Route.Public.CreateDemand()} style={{ width: '100%' }}>
                        <Button
                          w="full"
                          h="53px"
                          mb="48px"
                          onClick={() => {
                            onClose()
                          }}
                        >
                          Vytvoriť dopyt
                        </Button>
                      </Link>
                    )}
                  </VStack>
                </DrawerBody>
              </DrawerContent>
            </Drawer>
          </Flex>
        </Container>
      </Flex>
      <SignInModal isOpen={isSignInModalOpen} onClose={onSignInModalClose} />
    </>
  )
}
