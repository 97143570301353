import { forwardRef, ReactNode, useState } from 'react'
import {
  Box,
  Button,
  Flex,
  FormLabel,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
  LayoutProps,
  Stack,
  StackDirection,
  Text,
  VStack,
} from '@chakra-ui/react'
import { IconAsteriskSimple, IconEye, IconEyeOff } from '@tabler/icons-react'
import { ExplanatoryTooltip } from 'components/common/ExplanatoryTooltip'

export interface PasswordInputProps extends InputProps {
  id: string
  label?: string
  subLabel?: string
  labelDirection?: StackDirection
  inputWidth?: LayoutProps['w']
  errorMessage?: string
  isRequired?: boolean
  tooltip?: ReactNode
}

export const PasswordInput = forwardRef<HTMLInputElement, PasswordInputProps>(
  (
    {
      id,
      inputWidth = 'full',
      label,
      subLabel,
      labelDirection = 'column',
      errorMessage = '',
      isDisabled,
      isRequired,
      tooltip,
      ...rest
    },
    ref,
  ) => {
    const [isPasswordRevealed, setIsPasswordRevealed] = useState(false)

    return (
      <Stack
        justifyContent="space-between"
        direction={labelDirection}
        align="start"
        alignItems={labelDirection === 'row' ? 'center' : 'left'}
        spacing="1"
        w={inputWidth}
        style={{ columnGap: '30px' }}
      >
        {label && !subLabel && (
          <Flex w="full" justify="space-between">
            <FormLabel
              variant="outline"
              whiteSpace={{ base: 'balance', md: 'nowrap' }}
              display="flex"
            >
              {label} {isRequired && <IconAsteriskSimple size="12px" color="#BA0000" />}
            </FormLabel>
            {tooltip && <ExplanatoryTooltip label={tooltip} />}
          </Flex>
        )}
        {label && subLabel && (
          <VStack w="full" spacing={0} alignItems="start">
            <Flex w="full" justify="space-between">
              <FormLabel
                variant="outline"
                whiteSpace={{ base: 'balance', md: 'nowrap' }}
                display="flex"
              >
                {label} {isRequired && <IconAsteriskSimple size="12px" color="#BA0000" />}
              </FormLabel>
              {tooltip && <ExplanatoryTooltip label={tooltip} />}
            </Flex>
            <Text pl="4px" color="brand.darkBlue60" fontSize="13px">
              {subLabel}
            </Text>
          </VStack>
        )}
        <Box>
          <InputGroup justifyContent="flex-end">
            <Input
              id={id}
              name={id}
              ref={ref}
              {...rest}
              type={isPasswordRevealed ? 'text' : 'password'}
            />
            {!isDisabled && (
              <InputRightElement h="full" w="fit-content">
                <Button
                  variant="ghost"
                  onClick={() => {
                    setIsPasswordRevealed((prevState) => !prevState)
                  }}
                >
                  {isPasswordRevealed ? <IconEye /> : <IconEyeOff />}
                </Button>
              </InputRightElement>
            )}
          </InputGroup>
          <Flex mt="4px" h="20px">
            {errorMessage && (
              <Text variant="error" maxH="20px">
                {errorMessage}
              </Text>
            )}
          </Flex>
        </Box>
      </Stack>
    )
  },
)
