import { Button } from '@chakra-ui/react'
import Link from 'next/link'

export const NavbarButton = ({
  onClick,
  href,
  label,
}: {
  onClick?: () => void
  href: string
  label: string
}) => (
  <Link href={href}>
    <Button
      variant="tertiary"
      fontFamily="Montserrat Medium"
      fontSize={{ base: '24px', md: '14px', lg: '16px' }}
      lineHeight={{ base: '150%', lg: '40px' }}
      p={0}
      onClick={() => {
        onClick?.()
      }}
    >
      {label}
    </Button>
  </Link>
)
